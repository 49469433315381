<template>
  <!-- 司机管理 -->
  <div>
    <Modal
      :title="title"
      :value="value"
      @input="(val) => $emit('input', val)"
      :width="900"
      @on-visible-change="modalShow"
    >
      <Form :label-width="130" ref="form" :model="form" :rules="rules">
        <div
          style="
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
          "
        >
          <FormItem
            :show-message="false"
            style="margin-bottom: 0px"
            prop="logo"
            :label-width="0"
          >
            <upload-image
              v-if="!defaultEdeit"
              :max="1"
              :clear-flag="!value"
              v-model="form.img"
            >
            </upload-image>
            <div
              v-if="defaultEdeit"
              style="width: 80px; height: 80px; cursor: pointer"
              @click="checkImg"
            >
              <img v-if="imgdata==''" src="/imgs/empty/empty.png" alt="" style="width: 100%; height: 100%">
              <img v-else style="width: 100%; height: 100%" :src="imgdata" alt="" />
            </div>
          </FormItem>
          <span style="font-size: 14px; padding-top: 6px; color: #515a6e"
            >头像</span
          >
        </div>
        <Row>
          <Col span="12">
            <FormItem label="姓名" prop="name">
              <Input
                :disabled="defaultEdeit"
                clearable
                placeholder="请填写司机姓名"
                v-model="form.name"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="性别" prop="sex">
              <Select
                :disabled="defaultEdeit"
                v-model="form.sex"
                style="width: 260px"
                placeholder="请选择性别"
                clearable
              >
                <Option :value="1">男</Option>
                <Option :value="2">女</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="驾驶证编号" prop="driverNo">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请填写驾驶证编号"
                v-model="form.driverNo"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="从业资格有效期" prop="noValidPeriod">
              <DatePicker
                format="yyyy/MM/dd"
                type="date"
                :disabled="defaultEdeit"
                placeholder="请选择商业险期限"
                v-model="form.noValidPeriod"
                style="width: 260px"
              ></DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="工作证" prop="wordNo">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请填写工作证编号"
                v-model="form.wordNo"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="电话号码" prop="phone">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入电话号码"
                v-model="form.phone"
                maxlength="11"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="部门">
              <Select
                :disabled="defaultEdeit"
                v-model="form.deptId"
                style="width: 260px"
                placeholder="请选择所属部门"
                clearable
              >
                <Option
                  v-for="el in deptList"
                  :key="el.unitId"
                  :value="el.unitId"
                  >{{ el.unitName }}</Option
                >
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="出生日期" prop="birthday">
              <DatePicker
                type="date"
                :disabled="defaultEdeit"
                placeholder="请选择商业险期限"
                v-model="form.birthday"
                style="width: 260px"
              ></DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="地址" prop="address">
              <Input
                :disabled="defaultEdeit"
                placeholder="请输入地址"
                v-model="form.address"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="邮箱" prop="email">
              <Input
                clearable
                :disabled="defaultEdeit"
                placeholder="请输入邮箱"
                v-model="form.email"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => $emit('input', false)">取消</Button>
      </p>
    </Modal>
    <pic-view v-model="checkPic" :src="imgdata"></pic-view>
  </div>
</template>

<script>
import moment from "moment";
import until from "../../common/util";
import picView from "@/components/picture_view";
export default {
  name: "",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    DefluteValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    defaultEdeit: Boolean,
  },

  components: {
    // carDetail,
    picView,
  },
  data() {
    return {
      config: {
        filter: {
          add: {
            addBtnName: "新增司机",
            ca: "driver_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "姓名",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      deptList: [],
      imgdata: "",
      // modal: {
      //   show: false,
      //   title: "",
      //   submitLoading: false,
      // },
      // carDetail: {
      //   show: false,
      //   info: {},
      // },
      form: {
        img: "", //头像
        userId: "",
        name: "", //姓名
        wordNo: "", //工作证
        driverNo: "", //驾驶证编号
        companyNo: "", //公司编号
        noValidPeriod: "", //从业资格有效期
        sex: "", //性别
        phone: "", //联系电话
        email: "", //邮件
        deptId: "", //部门
        address: "", //地址
        birthday: "", //出生日期
      },
      rules: {
        name: [{ required: true, message: "请输入姓名" }],
        driverNo: [{ required: true, message: "请输入驾驶证编号" }],
        noValidPeriod: [{ required: true, message: "请输入从业资格有效期" }],
        sex: [{ required: true, message: "请选择性别" }],
        email: [
          // { required: true, message: "请填写邮箱" },
          {
            validateEmail(rule, value, callback) {
              if (
                !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
                  value
                )
              ) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "邮箱格式不正确",
            trigger: "change",
          },
        ],
        phone: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        // deptId: [{ required: true, message: "请选择所属部门" }],
        // address: [{ required: true, message: "请输入地址" }],
        birthday: [{ required: true, message: "请选择出生日期" }],
        wordNo: [{ required: true, message: "请输入工作证编号" }],
      },
      //图片弹框
      checkPic: false,
    };
  },
  methods: {
    submit() {
      let params = { ...this.form };
      let url;
      if (params.userId) {
        url = this.$api.DRIVER_MANAGE.UPDATE;
      } else {
        url = this.$api.DRIVER_MANAGE.ADD;
      }
      params.noValidPeriod = moment(this.form.noValidPeriod).format(
        "YYYY-MM-DD"
      );
      params.birthday = moment(this.form.birthday).format("YYYY-MM-DD");
      let index = this.form.img ? this.form.img.indexOf("/", 10) : -1;
      if (index !== -1) {
        params.img = this.form.img.substring(index + 1);
      }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, { account: this.form.phone, ...params })
          .then(() => {
            this.$Message.success(params.userId ? "编辑成功！" : "添加成功！");
            this.DriverModel = false;
            this.$emit("input", false);
            this.$emit("refrestList");
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    //获取部门列表
    getDeptList() {
      this.$post(this.$api.company.BARNCHTREE)
        .then((res) => {
          this.deptList = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //查看图片
    checkImg() {
      this.checkPic = true;
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        userId: "",
        img: "",
        name: "",
        driverNo: "",
        companyNo: "",
        noValidPeriod: "",
        phone: "",
        email: "",
        birthday: "",
        address: "",
        birthday: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getDeptList();
  },
  watch: {
    value(val) {
      if (val) {
        if (this.DefluteValue) {
          //执行初始化model操作
          let formData = {};
          formData = JSON.parse(JSON.stringify(this.DefluteValue));
          this.form.deptId = formData.deptid; //部门
          let dept = [];
          dept = JSON.parse(JSON.stringify(this.deptList));
          for (let i = 0; i < dept.length; i++) {
            if (dept[i].deptid == this.form.deptId) {
              this.form.deptId = dept[i].id;
            }
          }
          this.form.userId = formData.id;
          this.form.name = formData.name; //姓名
          this.form.wordNo = formData.wordNo; //工作证
          this.form.driverNo = formData.driverNo; //驾驶证编号
          this.form.companyNo = formData.companyNo; //公司编号
          this.form.noValidPeriod = formData.noValidPeriod; //从业资格有效期
          this.form.sex = formData.sex; //性别
          this.form.phone = formData.phone; //联系电话
          this.form.email = formData.email; //邮件
          this.form.address = formData.address; //地址
          this.form.birthday = formData.birthday; //出生日期
          
          if (formData.img) {
            this.$nextTick(() => {
              this.form.img = formData.servicePath + formData.img; //头像
              this.imgdata = formData.servicePath + formData.img;
            });
          }
          if(formData.img==''){
            this.imgdata=''
          }
        }
      } else {
        //   执行重置数据操作
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>